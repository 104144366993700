const smoothScroll = () => {
  $('a[href^="#"]').on('click', e => {
    let speed = 600;
    let href= $(e.currentTarget).attr('href');
    let target = $(href == '#' || href == '' ? 'html' : href);
    let position = target.offset().top;
    $('body, html').delay(200).animate({scrollTop: position}, speed, 'swing');
    return false;
  });
};

const swiperSlider = () => {
  if ($('.topPageFvSec__sliderArea').length) {


    var topPageFvSecSlider = new Swiper('.topPageFvSec__sliderArea', {
      // autoplay: {
      //   delay: 5000, // 自動スライド・1スライド滞在時間
      //   disableOnInteraction: false, // ユーザーがスライド操作した後もautoplay続行
      // },
      watchSlidesProgress: true, // 進捗状態を計算するオプション
      watchSlidesVisibility: true, // 画面に表示されているスライドにswiper-slide-visibleのclassがつく
      speed: 500, // スライド切替スピード
      loop: true, // ループ(クローンスライドが作られる)
      loopedSlides: 7, // ループする数(下のスライド枚数に合わせている)
      spaceBetween: 0, // スライド間のmarginは0px
      effect: 'fade', // エフェクト
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        prevEl: '.topPageFvSec__sliderArea .swiper-button-prev', // 前へボタン
        nextEl: '.topPageFvSec__sliderArea .swiper-button-next', // 次へボタン
      },
      pagination: {
        el: '.topPageFvSec .swiper-pagination', // ページャー
        clickable: true, // ページャークリッカブル
      },
    });
  }

  if ($('.topPageFvSec__sliderAreaThumb').length) {
    var sliderThumbnail = new Swiper('.topPageFvSec__sliderAreaThumb', {
      autoplay: {
        delay: 5000, // 自動スライド・1スライド滞在時間
        disableOnInteraction: false, // ユーザーがスライド操作した後もautoplay続行
      },
      watchSlidesProgress: true, // 進捗状態を計算するオプション
      watchSlidesVisibility: true, // 画面に表示されているスライドにswiper-slide-visibleのclassがつく
      speed: 1500, // スライド切替スピード
      loop: true, // ループ(クローンスライドが作られる)
      slidesPerView: 3, // スライド枚数
      loopedSlides: 7, // ループする数(下のスライド枚数に合わせている)
      slideToClickedSlide: true, // スワイプ中にクリックした時、今の動きをキャンセルしてクリックした側のスライドに移動する
      navigation: {
        prevEl: '.topPageFvSec__sliderAreaThumb .swiper-button-prev', // 前へボタン
        nextEl: '.topPageFvSec__sliderAreaThumb .swiper-button-next', // 次へボタン
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
      },
    });

    sliderThumbnail.controller.control = topPageFvSecSlider;
    topPageFvSecSlider.controller.control = sliderThumbnail;

  }
};


const btnClickFunc = () => {
  const $btns = $('.js-btn');

  $btns.on('click', e => {
    const btnTargetData = $(e.currentTarget).attr('data-btn');
    const btnAnimationData = $(e.currentTarget).attr('data-animation');
    const target = $(`[data-target = "${btnTargetData}"]`);
    const btnTargetGroupData = $(e.currentTarget).attr('data-btnGroup');
    const $sameBtns = $(`[data-btnGroup = "${btnTargetGroupData}"]`);
    const $sameTargets = $(`[data-targetGroup = "${btnTargetGroupData}"]`);

    if (btnAnimationData == 'slide') {
      $(e.currentTarget).toggleClass('js-active');
      target.slideToggle(300);
    } else if (btnAnimationData == 'class') {
      $(e.currentTarget).toggleClass('js-active');
      target.toggleClass('js-active');
    } else if (btnAnimationData == 'singleActiveClass') {
      $(e.currentTarget).toggleClass('js-active');
      $sameBtns.not($(e.currentTarget)).removeClass('js-active');
      target.toggleClass('js-active');
      $sameTargets.not(target).removeClass('js-active');
    } else if (btnAnimationData == 'fade') {
      $(e.currentTarget).toggleClass('js-active');
      target.fadeToggle(300);
    } else if (btnAnimationData == 'commonFade') {
      $sameBtns.toggleClass('js-active');
      target.fadeToggle(300);
    } else if (btnAnimationData == 'allClass') {
      $(e.currentTarget).toggleClass('js-active');
      $sameTargets.addClass('js-active');
    } else if (btnAnimationData == 'tab') {
      $sameBtns.not($(e.currentTarget)).removeClass('js-active');
      $(e.currentTarget).addClass('js-active');
      $sameTargets.removeClass('js-active');
      target.addClass('js-active');
    } else if (btnAnimationData == 'pageTop') {
      $('body, html').animate({
        scrollTop: 0
      }, 700);
      return false;
    } else {
      $(e.currentTarget).toggleClass('js-active');
    }
  });
};


const scrollFunc = () => {
  const windowHeight = $(window).innerHeight();
  const target = $('.js-scrollFadeObj');

  $(window).on('scroll', e => {
    const scrollCount = $(window).scrollTop();

    if ( scrollCount >= windowHeight ) {
      target.addClass('js-active');
    }
    else {
      target.removeClass('js-active');
    }
  });
};


const fancyBoxModal = () => {
  $('[data-fancybox]').fancybox({
    loop: true, // 複数画像表示時に最初と最後をループさせる
    transitionEffect: 'slide', // スライド時のアニメーション効果の種類
    transitionDuration: 1000, // スライド時のアニメーションにかかる時間
    animationEffect: 'fade', // 画像ズーム時のアニメーションの種類
    animationDuration: 800, // ズーム時のアニメーションにかかる時間
    infobar: true, // 上部にインフォメーションバーを表示するか否か
  });
}


const loadingFunc = () => {
  const $loadingArea = $('.js-loadingArea');
  const $topPage = $('.js-topPageWrap');

  $(window).on('load', e => {
    $.cookie('access', 'save', { expires: 7 }); // accessというキーで適当な値をcookieに保存。7日間有効
  });

  const visitFirstFunc = () => {
    $loadingArea.addClass('js-active'); // accessの値がない場合(初訪問)
    $topPage.addClass('js-active'); // accessの値がない場合(初訪問)
  }

  if( $.cookie('access') ) {
    $loadingArea.remove(); // accessの値がある場合(リピーター)
    $topPage.addClass('js-active'); // accessの値がある場合(リピーター)
  } else {
    visitFirstFunc();
  }

  $loadingArea.on('click', e => {
    visitFirstFunc();
  });
}


const wowAnimation = () => {
  new WOW().init();
}


const calenderFunc = () => {
  const replaceText01 = () => {
    $('.ai1ec-month').each( function () {
      const $monthTexts = $(this).text();
      $(this).text( $monthTexts.replace( /月/g, " /" ) );
    });
  }

  const replaceText02 = () => {
    $('.ai1ec-event').not('.ai1ec-allday').find('.ai1ec-event-time').each( function () {
      const $eventMonthTexts = $(this).text(); // 予定の月日部分
      const $eventMonthTextsArray = $eventMonthTexts.split('@', 1);
      $(this).text( $eventMonthTexts.replace( $eventMonthTextsArray + '@', '' ) );
    });
  }

  // ページに入った時に実行
  if (document.getElementById('ai1ec-calendar-view-container')) {
    replaceText01();
    replaceText02();
  }
  observCalender(replaceText01, 'display: block; opacity: 1;');
  observCalender(replaceText02, 'display: block; opacity: 1;');
}

/**   カレンダーの変更を検知する関数  */
/**  @param {function} doFunc 実行するコールバック */
/**  @param {string} state styleの文字列*/

const observCalender = (doFunc,state) => {
  // カレンダーのプラグインがある時
  if(document.getElementById('ai1ec-calendar-view-container')) {
    // 監視する要素
    const target = document.getElementById('ai1ec-calendar-view-loading');

    // 監視👀👀👀👀👀👀👀👀
    const observer = new MutationObserver(()=> {
      // style属性の文字列を取得
      const style = target.getAttribute('style');
      // targetのstyle
      if( style === state) {
        // 引数のコールバックを実行する
        doFunc();
      }
    });

    // 監視するオプション
    const options = {
      attributes: true,
      attributeFilter: ['style'] // style属性を監視
    };
    observer.observe(target, options);

    // 監視を停止する場合
    // let shouldStopObserving = false;
    // if(shouldStopObserving){
    //   observer.disconnect();
    // }
  }
}

jQuery(function($){
  $('.tab').click(function(){
    $('.is-active').removeClass('is-active');
    $(this).addClass('is-active');
    $('.is-show').removeClass('is-show');
    // クリックしたタブからインデックス番号を取得
    const index = $(this).index();
    // クリックしたタブと同じインデックス番号をもつコンテンツを表示
    $('.panel').eq(index).addClass('is-show');
  });
});

function tabSec() {
  var TabList = function(elParent,contentsParent) {
    this.elParent = $(elParent);
    this.el = this.elParent.find('.one_tab');
    this.contentsParent = $(contentsParent);
    this.contents = this.contentsParent.find('.tab_main');
  };
  TabList.prototype.fn = function() {
    var that = this;
    this.el.on('click', function() {
      var index = $(this).index();
      that.elParent.each(function(){
        $('.one_tab',this).removeClass('select').eq(index).addClass('select');
      });
      that.contents.removeClass('is_show').eq(index).addClass('is_show');
      if($(this).parent().hasClass('bottom')){
        scrollToHash();
      }
    });
    function scrollToHash(){
      var speed = 400;
      var target = that.contentsParent;
      var position = target.offset().top - 360;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    }
  };
  var syncTab = new TabList('.tabBox', '.contents');
  syncTab.fn();
}


const specialForm = () =>  {
  $('#spec_info-1').parents('.horizontal-item').append('<p class="pagePressFormSec__wrap__formArea__list__item__textArea__radioBtnWrap__innerText u-topText input-show">ご記入いただきましたメールアドレスにご連絡させていただきます。</p>');
}

const checkedBtn = () => {
  var nowchecked = $('input[name=spec_info]:checked').val();
  $('input[name=spec_info]').click(function(){
    if($(this).val() == nowchecked) {
      $(this).prop('checked', false);
      nowchecked = false;
    } else {
      nowchecked = $(this).val();
    }
  });
}


function goudouboSpecial() {
  // var goudouboBtn = $('.pageSpecialPageContentSec__infoArea');
  // //ボタンを非表示にする
  // goudouboBtn.hide();
  // //スクロールしてページトップから100に達したらボタンを表示
  // $(window).scroll(function () {
  //   if ($(this).scrollTop() > 200) {
  //     //フェードインで表示
  //     goudouboBtn.fadeIn();
  //   } else {
  //     //フェードアウトで非表示
  //     goudouboBtn.fadeOut();
  //   }
  // });
  //フッター手前でボタンを止める（ここを追加する）
  // $(window).scroll(function () {
  //   var height = $(document).height(); //ドキュメントの高さ
  //   var position = $(window).height() + $(window).scrollTop(); //ページトップから現在地までの高さ
  //   var footer = $("footer").height(); //フッターの高さ
  //   if ( height - position  < footer ) {
  //     goudouboBtn.css({
  //       position : "absolute",
  //       // top : -70
  //     });
  //   } else {
  //     goudouboBtn.css({
  //       position : "fixed",
  //       // top: 150
  //     });
  //   }
  // });
  //スクロールしてトップへ戻る
  // goudouboBtn.click(function () {
  //   $('body,html').animate({
  //     scrollTop: 0
  //   }, 500);
  //   return false;
  // });
}

function modalGouto() {
  $('.js-modal-open').on('click',function(){
    $('.js-modal').fadeIn();
    return false;
  });
  $('.js-modal-close').on('click',function(){
    $('.js-modal').fadeOut();
    return false;
  });
}


function PageClubBnr() {
  $(function() {
    var TopBtn = $('#PageClubBnr');
    // TopBtn.show();

    //スクロール中はボタンを非表示
    $(window).on("scroll touchmove", function(){
      TopBtn.stop();
      TopBtn.css('display', 'none').delay(200).fadeIn('fast');
    });

    //スクロール位置が0でボタンを表示
    $(window).scroll(function() {
      if ($(this).scrollTop() > 0) {
        TopBtn.fadeIn();
      }
      else {
        TopBtn.fadeOut();
      }
    });
  });
}



$(function() {
  // loadingFunc();
  smoothScroll();
  btnClickFunc();
  scrollFunc();
  fancyBoxModal();
  wowAnimation();
  calenderFunc();
  swiperSlider();
  tabSec();
  specialForm();
  checkedBtn();
  modalGouto();
  PageClubBnr();
});
